import './MarkServedModal.scss';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ContentType, FormCode, ManifestType, ServeStateRecipient, SigningParty, SigningPartySource } from '@property-folders/contract';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { uuidv4 } from 'lib0/random';
import * as Y from 'yjs';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { AnyAction, Store } from 'redux';
import { useStore } from 'react-redux';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useQpdfWorker } from '../../hooks/useQpdfWorker';
import { WorkflowConditionTriggerType } from '@property-folders/contract/yjs-schema/entity-settings';
import { useCurrentEntity } from '../../hooks/useEntity';
import { evaluateCondition } from '@property-folders/common/workflow-rules/RuleEvaluation';
import { materialiseProperty } from '@property-folders/common/yjs-schema/property';
import { getContractForForm1Recipient, shouldCoolingOffStartNow } from '@property-folders/common/util/form';

export function MarkServedModal({
  formCode,
  formId,
  propertyId,
  recipient,
  onClose,
  purchaser
}: {
  formCode: string,
  formId: string,
  propertyId: string,
  recipient: ServeStateRecipient,
  onClose: () => void,
  purchaser?: { signingParty: SigningParty, source: SigningPartySource, recipient: ServeStateRecipient, sublineage: string, sourceKey: string }
}) {
  const recipientType = 'purchaser';
  const label = FormTypes[formCode].label;
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const [show, setShow] = useState(true);

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const [pdf, setPdf] = useState<{filename:string, data:Uint8Array|ArrayBuffer}|undefined>(undefined);
  const [servedDate, setServedDate] = useState<string|number>('');
  const [confirmText, setConfirmText] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [alterFile, setAlterFile] = useState(false);
  const [showCoolingOffWarning, setShowCoolingOffWarning] = useState(false);
  const store = useStore();
  const { instance: fileSync } = useContext(FileSyncContext);
  const qpdfWorker = useQpdfWorker();
  const localEntity = useCurrentEntity();

  const now = new Date();
  // toISOString may return in UTC, which could result in odd date alignment
  const todayDateStr = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2,'0')}-${(now.getDate()).toString().padStart(2,'0')}`;
  const todayDateTimestamp = new Date(todayDateStr).valueOf();

  const contract = useMemo(()=> {
    if (!ydoc) return;
    const property = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey).getMaterialisedProperty();
    return getContractForForm1Recipient(property, recipient);
  }, [recipient, ydoc]);

  useEffect(() => {
    if (recipient?.manuallyServed) {
      setAlterFile(true);
    }
  }, [recipient]);

  //Cooling-off warning
  useEffect(() => {
    setShowCoolingOffWarning(shouldShowCoolingOffWarning());
  }, [servedDate, todayDateTimestamp, ydoc, localEntity?.workflowRules, purchaser]);

  const shouldShowCoolingOffWarning = () => {
    if (!ydoc || !servedDate) return false;

    const rules = localEntity?.workflowRules;
    const applicableRules = rules?.filter(r => r.trigger === WorkflowConditionTriggerType.CoolingOffBegun && r.enabled && r.conditions?.length);
    if (!applicableRules?.length) return false;

    const localProperty = materialiseProperty(ydoc);
    if (!localProperty) return false;

    // augment the recipient locally to determine whether we are actually preventing a cooling-off
    const recipientToAugment = localProperty?.meta?.formStates?.[FormCode.Form1]?.recipients?.find(r => r.id === recipient.id);
    if (!recipientToAugment) return false;

    //if serving today wouldn't cause cooling-off anyway, then we dont need to show the warning
    recipientToAugment.manuallyServed = { servedDate: todayDateTimestamp, timestamp: todayDateTimestamp };
    if (!shouldCoolingOffStartNow(localProperty, contract?.sublineageId)) return false;

    //if serving at the date the user specified would still allow cooling-off, we dont need to show the warning
    recipientToAugment.manuallyServed = { servedDate: servedDate, timestamp: todayDateTimestamp };
    if (shouldCoolingOffStartNow(localProperty, contract?.sublineageId)) return false;

    //now check the actual event rule will pass
    const event = {
      trigger: WorkflowConditionTriggerType.CoolingOffBegun,
      propertyId: propertyId,
      sublineageDataKey: purchaser?.sublineage
    };

    const matProperty = materialiseProperty(ydoc);
    return !!(matProperty && applicableRules?.some(rule => rule?.conditions?.[0] && evaluateCondition(rule.conditions[0], matProperty, event)));
  };

  const handleDrop = async (acceptedFiles: File[] | FileList) => {
    const file = Array.isArray(acceptedFiles)
      ? acceptedFiles.at(0)
      : acceptedFiles.item(0);
    if (!file) {
      setErrorMessage('Failed to attach file. Please make sure PDF is specified');
      return;
    }
    try {
      const buf = await file?.arrayBuffer();
      const decryptedPdf = await qpdfWorker.decrypt({ pdf: buf });
      const result = await processPdf(decryptedPdf);
      if (!result?.pdf || result?.isEncrypted) {
        throw new Error('Could not attach PDF');
      }

      setErrorMessage('');
      setPdf({
        filename: file?.name || 'document.pdf',
        data: result.pdf
      });
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage(`Failed to attach ${file?.name || 'file'}. Please make sure PDF is not encrypted and try again`);
    }
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    await handleDrop(event.target.files);
  };

  const { getRootProps, getInputProps, isDragAccept  } = useDropzone({
    onDrop: handleDrop,
    noClick: false,
    multiple: false,
    accept: { [ContentType.Pdf]: [] }
  });

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setPdf(undefined);
  };

  const handleChooseClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    uploadInput.current?.click();
  };

  const handleMarkServed = async () => {
    if (!ydoc) return;

    if (confirmText.toUpperCase() !== 'MANUAL') return;

    try {
      if (alterFile) {
        if (!pdf) {
          return;
        }
        await alterServedCopy({ ydoc, file: pdf.data, propertyId, formId, formCode, recipientId: recipient.id, store, fileSync, dataRootKey: transactionRootKey, metaRootKey: transactionMetaRootKey });
      } else {
        await markServed({ ydoc, file: pdf?.data, propertyId, formId, formCode, recipientId: recipient.id, store, fileSync, dataRootKey: transactionRootKey, metaRootKey: transactionMetaRootKey, servedDate });
      }
      setShow(false);
      onClose();
    } catch (err: unknown) {
      console.error(err);
      if (alterFile) {
        setErrorMessage('There was a problem updating the served file. Please try again.');
      } else {
        setErrorMessage('There was a problem marking as served. Please try again.');
      }
    }
  };

  return <Modal className='mark-served-modal' show={show} onHide={handleCancel}>
    <Modal.Header closeButton>
      {alterFile
        ? <Modal.Title>Update Signed Copy</Modal.Title>
        : <Modal.Title>Mark Served</Modal.Title>}
    </Modal.Header>
    <Modal.Body>
      <div>
        {alterFile
          ? <div className="mb-3">
          Update the signed copy served to {recipientType}.
          </div>
          : <div className="mb-3">
          Record that {label} has been served to this {recipientType}.
          </div>}
        <div>
          <input {...getInputProps()} ref={uploadInput} className={'d-none'} accept={'.pdf'} onChange={handleUpload} />

          <div style={{ height: '140px' }} {...getRootProps()} className={clsJn('drop-target mb-3', isDragAccept && 'drop-accept', pdf && 'is-uploaded' )} tabIndex={-1}>
            {pdf
              ? <div className={'d-flex text-black'}>
                <Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/>
                {pdf.filename}
                <span className="material-symbols-outlined cursor-pointer ms-2" onClick={handleDeleteClick}>delete</span>
              </div>
              : <>
                <div className={'mb-2'}>Drag and drop the signed PDF</div>
                <div className={'mb-2'}>or</div>
                <Button variant={'primary'} className={'mr-3'} onClick={handleChooseClick}>Choose PDF</Button>
              </>}
          </div>

          {!alterFile && <div className={'mt-2'}>
            Served Date
            <Form.Control
              type={'date'}
              style={{ maxWidth: '200px', padding: '2px 2px' }}
              value={ servedDate ? new Date(servedDate).toISOString().split('T')[0] : undefined}
              max={todayDateStr}
              onChange={(e) => {
                const dateTimestamp = new Date(e.target.value).valueOf();
                !(dateTimestamp > todayDateTimestamp) && setServedDate(dateTimestamp);
              }}
            />

            <div className={'mt-3'}>
              <div className={'mb-1'}>To confirm the details entered, type "MANUAL" below:</div>
              <Form.Control placeholder={'MANUAL'} style={{ width: '200px' }} value={confirmText} onChange={e => setConfirmText(e.target.value)}></Form.Control>
            </div>
          </div>}

          {showCoolingOffWarning && <Alert variant='warning' className='mt-3'>
            The Form 1 has been served on paper, in the past. Greatforms will not send "Cooling Off Begins" notifications for this transaction.
          </Alert>}
          {errorMessage && <Alert variant='danger' dismissible onClose={()=>setErrorMessage('')}>{errorMessage}</Alert>}
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleCancel}>Cancel</Button>
      {alterFile
        ? <AsyncButton disabled={!pdf} onClick={handleMarkServed}>Apply Update</AsyncButton>
        : <AsyncButton onClick={handleMarkServed}>Mark Served</AsyncButton>}
    </Modal.Footer>
  </Modal>;
}

async function markServed({
  ydoc,
  file,
  propertyId,
  formCode,
  formId,
  recipientId,
  store,
  fileSync,
  dataRootKey,
  metaRootKey,
  servedDate
}: {
  ydoc: Y.Doc,
  file?: Uint8Array | ArrayBuffer,
  propertyId: string,
  formCode: string,
  formId: string,
  recipientId: string,
  store: Store<unknown,AnyAction>,
  fileSync: FileSync
  dataRootKey: string
  metaRootKey: string
  servedDate: string
}) {
  const id = uuidv4();
  if (file) {
    await FileStorage.write(
      id,
      FileType.PropertyFile,
      ContentType.Pdf,
      new Blob([file], { type: ContentType.Pdf }),
      StorageItemSyncStatus.PendingUpload,
      {
        propertyFile: {
          propertyId,
          formId,
          formCode
        }
      },
      { store, ydoc },
      {
        manifestType: ManifestType.None
      },
      undefined
    );
    FileSync.triggerSync(fileSync);
  }

  new PropertyFormYjsDal(ydoc, dataRootKey, metaRootKey).markServed(
    formCode,
    formId,
    recipientId,
    file ? { id, contentType: ContentType.Pdf } : undefined,
    servedDate
  );
}

async function alterServedCopy({
  ydoc,
  file,
  propertyId,
  formCode,
  formId,
  recipientId,
  store,
  fileSync,
  dataRootKey,
  metaRootKey
}: {
  ydoc: Y.Doc,
  file: Uint8Array | ArrayBuffer,
  propertyId: string,
  formCode: string,
  formId: string,
  recipientId: string,
  store: Store<unknown,AnyAction>,
  fileSync: FileSync
  dataRootKey: string
  metaRootKey: string
}) {
  const id = uuidv4();
  await FileStorage.write(
    id,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([file], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId,
        formId,
        formCode
      }
    },
    { store, ydoc },
    {
      manifestType: ManifestType.None
    },
    undefined
  );

  FileSync.triggerSync(fileSync);

  new PropertyFormYjsDal(ydoc, dataRootKey, metaRootKey).alterServedCopy(
    formCode,
    formId,
    recipientId,
    { id, contentType: ContentType.Pdf }
  );
}
