import { FieldPosition, PendingRemoteSigningSessionPage } from '@property-folders/contract';
import { renderTextSimple } from '@property-folders/common/util/image';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { Image } from 'react-bootstrap';
import { asPercentage } from './common';
import { useMemo } from 'react';

export function Timestamp(props: { page: PendingRemoteSigningSessionPage; location: FieldPosition; value?: number; timeZone: string, appearance?: { fontSize?: number }}) {
  const {
    location,
    page: {
      width: pageWidth,
      height: pageHeight
    },
    value,
    timeZone,
    appearance
  } = props;

  const asImage = useMemo(() => {
    if (!(value && value > 0)) return undefined;
    return renderTextSimple({
      text: formatTimestamp(value, timeZone, false),
      containingBox: location,
      fontSize: appearance?.fontSize ?? 12
    });
  }, [value]);

  return <div
    style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    {asImage && <Image src={asImage} style={{ objectFit: 'contain' }} />}
  </div>;
}
