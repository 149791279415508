import { useContext, useEffect } from 'react';
import { FileStorage, FileType, IFileRelatedData } from '@property-folders/common/offline/fileStorage';
import { useLiveQuery } from 'dexie-react-hooks';
import { ContentType } from '@property-folders/contract';
import { FileSync, propertyIdFromRelated } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';

export function useFileRef(fileId?: string, fileType?: FileType, contentType?: ContentType, relatedData?: IFileRelatedData) {
  const { instance: fileSync } = useContext(FileSyncContext);
  // generally this won't do anything.
  // if there is no logo loaded, then make an attempt here to do so.
  useEffect(() => {
    if (!(fileId && contentType && fileType != null && relatedData)) return;
    if (fileType === FileType.PropertyFile && !propertyIdFromRelated(relatedData)) {
      console.error('Property file without a property id requested');
      return;
    }
    if (!fileSync) return;

    (async () => {
      try {

        const result = await FileStorage.read(fileId);

        if (result) {
          return;
        }

        await FileStorage.queueDownload(
          fileId,
          fileType,
          contentType,
          relatedData // This was bad (was passing an empty object), but it probably isn't the no propertyId bug, because it was also checking that fileType was not falsey, and the property file type is 0, so would have just returned before getting here
        );

        FileSync.triggerSync(fileSync);
      } catch (err: unknown) {
        console.error(err);
      }
    })();
  }, [fileId, fileSync]);

  return useLiveQuery(async () => {
    if (!fileId) return null;
    const data = await FileStorage.readFileOnly(fileId);
    if (!data?.size) return null;
    if (contentType !== data.type as ContentType) return null;

    return data;
  }, [fileId]);
}
